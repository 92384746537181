import styles from '../CustomDonationWidget/index.module.scss';
import React from 'react';
import { RemoveProduct } from '../../store/Slices/gifts/basketSlice';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

const RemoveProductButton = ({ productId, onRemoveProduct = null }) => {
  const dispatch = useDispatch();
  const removeProduct = (e) => {
    if (e.type === 'click') {
      e.preventDefault();

      if (onRemoveProduct) {
        onRemoveProduct(e, productId);
        return;
      }

      dispatch(RemoveProduct(productId));
      return;
    }

    if (e.code === 'Enter' || e.code === 'Space') {
      if (onRemoveProduct) {
        onRemoveProduct(e, productId);
        return;
      }
      dispatch(RemoveProduct(productId));
    }
  };
  return (
    <a
      href="#"
      role="button"
      className={styles['custom-donation-widget__remove']}
      onClick={removeProduct}
      onKeyDown={removeProduct}
      data-test="remove-product-button"
    >
      Remove
    </a>
  );
};

RemoveProductButton.propTypes = {
  productId: PropTypes.number.isRequired,
  onRemoveProduct: PropTypes.func,
};

export default RemoveProductButton;
