import React from 'react';
import PropTypes from 'prop-types';
import Link from '../../Link';
import {
  isCustomPrice,
  hasFullView,
  isCorporateGift,
  isFreeProduct,
} from '../../../utils/Gifts/products';
import { getProductQuantity } from '../../../utils/Gifts/basket';
import ProductPrice from '../../ProductPrice';
import DefaultPicture from '../../../components/01_atoms/DefaultPicture';
import RemoveProductButton from '../../RemoveProductButton';

const BasketWidgetProductItem = ({ product, handleDeleteClick = null, withLinks = false }) => (
  <div className={`item ${isCustomPrice(product.data) ? 'custom-donation' : ''}`}>
    {/* Link title to the usual products */}
    {withLinks && hasFullView(product.data) && (
      <Link href={product.data.url.url}>
        <a className="item-thumb">
          {product.data && product.data.image && <DefaultPicture {...product.data.image} />}
        </a>
      </Link>
    )}

    {(!withLinks || !hasFullView(product.data)) && (
      <span className="item-thumb">
        {product.data && product.data.image && <DefaultPicture {...product.data.image} />}
      </span>
    )}

    <div className="item-details">
      <h3 className="item-title">
        {isCustomPrice(product.data) && !isCorporateGift(product.data) && 'Custom donation'}
        {isFreeProduct(product.data) && product.data.title}

        {!withLinks &&
          hasFullView(product.data) &&
          `${product.data.title} (${getProductQuantity(product)})`}

        {/* Link title to the usual products */}
        {withLinks && hasFullView(product.data) && (
          <span>
            <Link href={product.data.url.url}>
              <a>{product.data.title}</a>
            </Link>{' '}
            ({getProductQuantity(product)})
          </span>
        )}
      </h3>
      <h4 className="item-price">
        <ProductPrice product={product.data} />
      </h4>
      {!isFreeProduct(product.data) && (
        <RemoveProductButton productId={product.id} onRemoveProduct={handleDeleteClick} />
      )}
    </div>
  </div>
);

BasketWidgetProductItem.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    quantity: PropTypes.number,
    data: PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      title: PropTypes.string,
      categoryIds: PropTypes.arrayOf(PropTypes.number),
      image: PropTypes.object,
      url: PropTypes.object,
      shortDescription: PropTypes.string,
      relatedGiftsFilled: PropTypes.arrayOf(PropTypes.number),
      actionImage: PropTypes.object,
      actionDescription: PropTypes.string,
      annotation: PropTypes.string,
      giftsInBundle: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  handleDeleteClick: PropTypes.func,
  withLinks: PropTypes.bool,
};

export default BasketWidgetProductItem;
